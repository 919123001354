.App {
  text-align: center;
  background-color: rgb(253, 253, 253);
  height: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: rgb(253, 204, 251);
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}
